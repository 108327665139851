export const slugify = (text: string, divider: "-"|"_" = "-") => {
    if(divider === "_"){
      return text.toString()
      .toLowerCase()
      .normalize("NFD")
      .trim()
      .replace(/\s+/g, "_")
      .replace(/[^\w\_]+/g, "")
      .replace(/\_\_+/g, "_");
    }
    return text.toString()
    .toLowerCase()
    .normalize("NFD")
    .trim()
    .replace(/\s+/g, "-")
    .replace(/[^\w\-]+/g, "")
    .replace(/\-\-+/g, "-");
  };
